import { AuthenticationError, AuthorizationError, BadRequestError, NotFoundError, type ValidationErrorMessage } from "@gasparigit/omnia-sdk";
import { toast } from "vue-sonner";
import ToastCheckIcon from "~/components/ToastCheckIcon.vue";

export function processError(error: any) {
  error = error as Error;

  if ("cause" in error) {
    showError({
      statusCode: 404,
      message: "Contenuto attualmente non disponibile",
    });

    return;
  }

  if (error instanceof BadRequestError) {
    errorToast((JSON.parse(error.message) as ValidationErrorMessage).message);

    return;
  }

  if (error instanceof NotFoundError) {
    showError({
      statusCode: 404,
      message: "Contenuto attualmente non disponibile",
    });

    throw error;
  }

  if (error instanceof AuthenticationError) {
    errorToast("La sessione è scaduta o non hai effettuato l'accesso");
    return;
  }

  if (error instanceof AuthorizationError) {
    if (error.message.startsWith("This action")) errorToast("Non hai le autorizzazioni per compiere questa azione");
    else if (error.message.startsWith("User")) errorToast("Non hai le autorizzazioni per accedere a questa sezione");
    else errorToast(error.message);

    return;
  }

  if (error.message === "Failed to fetch") {
    errorToast("Si è verificato un errore di connessione, riprova");

    return;
  }

  errorToast(error.message);
}

export function successToast(title: string = "Modifiche salvate", message?: string) {
  toast(title, {
    description: message,
    icon: h(ToastCheckIcon),
  });
}

export function errorToast(message: string) {
  toast("Errore", {
    description: message,
    icon: h(ToastCheckIcon, { error: true }),
  });
}

export const requiredError = {
  required_error: "Campo obbligatorio",
};
