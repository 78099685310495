<script setup lang="ts">
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/600.css";
import "@fontsource/titillium-web/200.css";
import "@fontsource/titillium-web/300.css";
import "@fontsource/titillium-web/400.css";
import "@fontsource/titillium-web/600.css";
import "@fontsource/titillium-web/700.css";

import "~/assets/css/main.css";

import { ConfigProvider } from "radix-vue";
import { Toaster } from "./components/ui/sonner";

updateSiteConfig({
  description: "Progetto Omnia è il portale per gli Enti Locali: strumenti integrati indispensabili per gli operatori della P.A. locale.",
});

const siteConfig = useSiteConfig();

useHead({
  htmlAttrs: {
    lang: "it",
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteConfig.name}` : siteConfig.name;
  },
  meta: [
    {
      name: "description",
      content: siteConfig.description,
    },
  ],
});

const useIdFunction = () => useId();
</script>

<template>
  <NuxtLoadingIndicator color="#f2983a" :height="5" />
  <ConfigProvider :use-id="useIdFunction">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Toaster position="top-center" />
    <CookieConsent />
  </ConfigProvider>
</template>
