
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91id_938vs9v4uqD1Meta } from "/app/pages/admin/aree/[id].vue?macro=true";
import { default as indexbzwoPMtbsDMeta } from "/app/pages/admin/aree/index.vue?macro=true";
import { default as index8OEhdn8qo9Meta } from "/app/pages/admin/blacklist/index.vue?macro=true";
import { default as _91id_93gc4vdHHBRNMeta } from "/app/pages/admin/clienti/[id].vue?macro=true";
import { default as indexQl4LbClEdwMeta } from "/app/pages/admin/clienti/index.vue?macro=true";
import { default as _91id_939SBngQLPivMeta } from "/app/pages/admin/contenuti/adempimento/[id].vue?macro=true";
import { default as indexgR2GQnApDnMeta } from "/app/pages/admin/contenuti/adempimento/index.vue?macro=true";
import { default as _91id_93PqIPjy9uyqMeta } from "/app/pages/admin/contenuti/articolo-norma/[id].vue?macro=true";
import { default as _91id_93US0yJnnTytMeta } from "/app/pages/admin/contenuti/articolo-rivista/[id].vue?macro=true";
import { default as _91id_9360VP6Dds0RMeta } from "/app/pages/admin/contenuti/link/[id].vue?macro=true";
import { default as indexd45tzOQfLjMeta } from "/app/pages/admin/contenuti/link/index.vue?macro=true";
import { default as _91id_93gtEGmmXyGuMeta } from "/app/pages/admin/contenuti/modello/[id].vue?macro=true";
import { default as indexMRYYGIOtjWMeta } from "/app/pages/admin/contenuti/modello/index.vue?macro=true";
import { default as _91id_93wcxNBoQF91Meta } from "/app/pages/admin/contenuti/norma/[id].vue?macro=true";
import { default as indexlMNKP1okJJMeta } from "/app/pages/admin/contenuti/norma/index.vue?macro=true";
import { default as _91id_93f6P3XNMDpIMeta } from "/app/pages/admin/contenuti/notizia/[id].vue?macro=true";
import { default as indexJfsy4PnczkMeta } from "/app/pages/admin/contenuti/notizia/index.vue?macro=true";
import { default as _91id_9334T9q98BUmMeta } from "/app/pages/admin/contenuti/postit/[id].vue?macro=true";
import { default as indexmGby7wc9U6Meta } from "/app/pages/admin/contenuti/postit/index.vue?macro=true";
import { default as _91id_93zOFjCLFwuxMeta } from "/app/pages/admin/contenuti/pratica/[id].vue?macro=true";
import { default as indexe3GT5srrF0Meta } from "/app/pages/admin/contenuti/pratica/index.vue?macro=true";
import { default as _91id_93yjSnhIStu0Meta } from "/app/pages/admin/contenuti/quesito/[id].vue?macro=true";
import { default as indexpsCLwEaAGsMeta } from "/app/pages/admin/contenuti/quesito/index.vue?macro=true";
import { default as _91id_93sXEAtNZaTYMeta } from "/app/pages/admin/contenuti/rivista/[id].vue?macro=true";
import { default as _91id_93s46WYRjnP3Meta } from "/app/pages/admin/contenuti/rivista/[rivista_id]/numero/[id].vue?macro=true";
import { default as _91id_93ezzZ2Zx4nAMeta } from "/app/pages/admin/contenuti/rivista/[rivista_id]/numero/[numero_id]/capitolo/[id].vue?macro=true";
import { default as index3yxgf1GBq1Meta } from "/app/pages/admin/contenuti/rivista/index.vue?macro=true";
import { default as _91id_934m3mQpkiy2Meta } from "/app/pages/admin/contenuti/scadenza-elettorale/[id].vue?macro=true";
import { default as _91id_93qoHXSgoxwGMeta } from "/app/pages/admin/contenuti/scadenza/[id].vue?macro=true";
import { default as indexIewUMYnYWpMeta } from "/app/pages/admin/contenuti/scadenza/index.vue?macro=true";
import { default as _91id_93B9xe8jvk7aMeta } from "/app/pages/admin/contenuti/scadenzario-elettorale/[id].vue?macro=true";
import { default as indexXd8Cz1YVVIMeta } from "/app/pages/admin/contenuti/scadenzario-elettorale/index.vue?macro=true";
import { default as _91id_934WaXe98YwIMeta } from "/app/pages/admin/drive/file/[id].vue?macro=true";
import { default as _91id_93IaB5JKga92Meta } from "/app/pages/admin/drive/folder/[id].vue?macro=true";
import { default as indexbS2HKUJe2lMeta } from "/app/pages/admin/drive/index.vue?macro=true";
import { default as _91id_93D99zjAhqBKMeta } from "/app/pages/admin/hub/[id].vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91id_93S48YjdTs9RMeta } from "/app/pages/admin/memoweb/[id].vue?macro=true";
import { default as indexLpfE5F1fiIMeta } from "/app/pages/admin/memoweb/index.vue?macro=true";
import { default as _91id_93q3EF9GaXkQMeta } from "/app/pages/admin/menu/[id].vue?macro=true";
import { default as indexDKCElKpBZ4Meta } from "/app/pages/admin/menu/index.vue?macro=true";
import { default as _91id_93afXk6LMZUSMeta } from "/app/pages/admin/newsletter/[id].vue?macro=true";
import { default as indexDq0GVBb93oMeta } from "/app/pages/admin/newsletter/index.vue?macro=true";
import { default as _91id_937GHQuocUhRMeta } from "/app/pages/admin/prodotti/[id].vue?macro=true";
import { default as indexSPRYqtRZvcMeta } from "/app/pages/admin/prodotti/index.vue?macro=true";
import { default as _91id_93Z7707EP2IMMeta } from "/app/pages/admin/quesiti-inviati/[id].vue?macro=true";
import { default as indexEU0sy0hGmvMeta } from "/app/pages/admin/quesiti-inviati/index.vue?macro=true";
import { default as _91id_935eLa1JKpPWMeta } from "/app/pages/admin/richieste-demo/[id].vue?macro=true";
import { default as indexE2JqIhihkWMeta } from "/app/pages/admin/richieste-demo/index.vue?macro=true";
import { default as _91id_93F97AbKAODtMeta } from "/app/pages/admin/utenti/[id].vue?macro=true";
import { default as indexZdxbZLjkEzMeta } from "/app/pages/admin/utenti/index.vue?macro=true";
import { default as indexTOJmOvSxOrMeta } from "/app/pages/admin/utilita/cache/index.vue?macro=true";
import { default as indexFuZJXcS3iAMeta } from "/app/pages/admin/utilita/contenuti/index.vue?macro=true";
import { default as _91id_93hQFqe3V1VvMeta } from "/app/pages/amministrazione/[id].vue?macro=true";
import { default as _91id_93_45_91slug_934lEAoSl3NxMeta } from "/app/pages/area/[area_slug]/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93fGzmihNvSYMeta } from "/app/pages/area/[area_slug]/hub/[hub_id]-[hub_slug]/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93uhL3tQnLseMeta } from "/app/pages/area/[area_slug]/hub/[id]-[slug].vue?macro=true";
import { default as notizielWKytVTEYxMeta } from "/app/pages/area/[area_slug]/notizie.vue?macro=true";
import { default as scadenzario7YzkuTXV75Meta } from "/app/pages/area/[area_slug]/scadenzario.vue?macro=true";
import { default as _91slug_93JnvVWuHKirMeta } from "/app/pages/area/[slug].vue?macro=true";
import { default as indexGh7tZs7A8yMeta } from "/app/pages/cerca/index.vue?macro=true";
import { default as indexX8cdImfCOUMeta } from "/app/pages/clienti/index.vue?macro=true";
import { default as indexZVCZGS6RPoMeta } from "/app/pages/cookie/index.vue?macro=true";
import { default as _91id_93_45_91slug_93jGuuFGjhL0Meta } from "/app/pages/dettaglio/[id]-[slug].vue?macro=true";
import { default as index8aQhp8I8K0Meta } from "/app/pages/dichiarazione-accessibilita/index.vue?macro=true";
import { default as _91id_932zYLWLBoiOMeta } from "/app/pages/file/[id].vue?macro=true";
import { default as indexd6swwL7Tp1Meta } from "/app/pages/gazzetta-e-portali-pubblici/index.vue?macro=true";
import { default as _91id_93_45_91slug_93lelKhSf4JLMeta } from "/app/pages/hub/[hub_id]-[hub_slug]/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93gMpZ5OJ6a4Meta } from "/app/pages/hub/[id]-[slug].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexE3P31Hw2raMeta } from "/app/pages/informazioni-per-il-cittadino/index.vue?macro=true";
import { default as _91id_93_45_91slug_93gxDkXq1xMIMeta } from "/app/pages/memoweb/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93jcC3B8QGZsMeta } from "/app/pages/memoweb/[memoweb_id]-[memoweb_slug]/[id]-[slug].vue?macro=true";
import { default as indexS0f4TgQwuGMeta } from "/app/pages/memoweb/index.vue?macro=true";
import { default as index2kzu542p1uMeta } from "/app/pages/myomnia/index.vue?macro=true";
import { default as indexYSJWAOuf8bMeta } from "/app/pages/note-legali/index.vue?macro=true";
import { default as indexsOHrcgJMlbMeta } from "/app/pages/notizie/index.vue?macro=true";
import { default as indextCDe67vQCCMeta } from "/app/pages/privacy/index.vue?macro=true";
import { default as _91id_93pmK9MWdiJHMeta } from "/app/pages/quesiti/[id].vue?macro=true";
import { default as indexRzhgyNgl8NMeta } from "/app/pages/quesiti/assegnati/index.vue?macro=true";
import { default as indexGN8QsuU7R2Meta } from "/app/pages/quesiti/index.vue?macro=true";
import { default as _91id_93_45_91slug_93a0vNVLntj1Meta } from "/app/pages/riviste/[id]-[slug].vue?macro=true";
import { default as _91id_93_45_91slug_93QrLr5JglqQMeta } from "/app/pages/riviste/[rivista_id]-[rivista_slug]/[id]-[slug].vue?macro=true";
import { default as indexqNdmgUvbMJMeta } from "/app/pages/riviste/index.vue?macro=true";
import { default as index1GEvujWNlkMeta } from "/app/pages/scadenzario/index.vue?macro=true";
import { default as index6cozR06FRIMeta } from "/app/pages/servizi/index.vue?macro=true";
import { default as index3Gq4tMcM7aMeta } from "/app/pages/tutorial/index.vue?macro=true";
export default [
  {
    name: "admin-aree-id",
    path: "/admin/aree/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/aree/[id].vue")
  },
  {
    name: "admin-aree",
    path: "/admin/aree",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/aree/index.vue")
  },
  {
    name: "admin-blacklist",
    path: "/admin/blacklist",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/blacklist/index.vue")
  },
  {
    name: "admin-clienti-id",
    path: "/admin/clienti/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/clienti/[id].vue")
  },
  {
    name: "admin-clienti",
    path: "/admin/clienti",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/clienti/index.vue")
  },
  {
    name: "admin-contenuti-adempimento-id",
    path: "/admin/contenuti/adempimento/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/adempimento/[id].vue")
  },
  {
    name: "admin-contenuti-adempimento",
    path: "/admin/contenuti/adempimento",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/adempimento/index.vue")
  },
  {
    name: "admin-contenuti-articolo-norma-id",
    path: "/admin/contenuti/articolo-norma/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/articolo-norma/[id].vue")
  },
  {
    name: "admin-contenuti-articolo-rivista-id",
    path: "/admin/contenuti/articolo-rivista/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/articolo-rivista/[id].vue")
  },
  {
    name: "admin-contenuti-link-id",
    path: "/admin/contenuti/link/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/link/[id].vue")
  },
  {
    name: "admin-contenuti-link",
    path: "/admin/contenuti/link",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/link/index.vue")
  },
  {
    name: "admin-contenuti-modello-id",
    path: "/admin/contenuti/modello/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/modello/[id].vue")
  },
  {
    name: "admin-contenuti-modello",
    path: "/admin/contenuti/modello",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/modello/index.vue")
  },
  {
    name: "admin-contenuti-norma-id",
    path: "/admin/contenuti/norma/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/norma/[id].vue")
  },
  {
    name: "admin-contenuti-norma",
    path: "/admin/contenuti/norma",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/norma/index.vue")
  },
  {
    name: "admin-contenuti-notizia-id",
    path: "/admin/contenuti/notizia/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/notizia/[id].vue")
  },
  {
    name: "admin-contenuti-notizia",
    path: "/admin/contenuti/notizia",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/notizia/index.vue")
  },
  {
    name: "admin-contenuti-postit-id",
    path: "/admin/contenuti/postit/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/postit/[id].vue")
  },
  {
    name: "admin-contenuti-postit",
    path: "/admin/contenuti/postit",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/postit/index.vue")
  },
  {
    name: "admin-contenuti-pratica-id",
    path: "/admin/contenuti/pratica/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/pratica/[id].vue")
  },
  {
    name: "admin-contenuti-pratica",
    path: "/admin/contenuti/pratica",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/pratica/index.vue")
  },
  {
    name: "admin-contenuti-quesito-id",
    path: "/admin/contenuti/quesito/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/quesito/[id].vue")
  },
  {
    name: "admin-contenuti-quesito",
    path: "/admin/contenuti/quesito",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/quesito/index.vue")
  },
  {
    name: "admin-contenuti-rivista-id",
    path: "/admin/contenuti/rivista/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/rivista/[id].vue")
  },
  {
    name: "admin-contenuti-rivista-rivista_id-numero-id",
    path: "/admin/contenuti/rivista/:rivista_id()/numero/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/rivista/[rivista_id]/numero/[id].vue")
  },
  {
    name: "admin-contenuti-rivista-rivista_id-numero-numero_id-capitolo-id",
    path: "/admin/contenuti/rivista/:rivista_id()/numero/:numero_id()/capitolo/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/rivista/[rivista_id]/numero/[numero_id]/capitolo/[id].vue")
  },
  {
    name: "admin-contenuti-rivista",
    path: "/admin/contenuti/rivista",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/rivista/index.vue")
  },
  {
    name: "admin-contenuti-scadenza-elettorale-id",
    path: "/admin/contenuti/scadenza-elettorale/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/scadenza-elettorale/[id].vue")
  },
  {
    name: "admin-contenuti-scadenza-id",
    path: "/admin/contenuti/scadenza/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/scadenza/[id].vue")
  },
  {
    name: "admin-contenuti-scadenza",
    path: "/admin/contenuti/scadenza",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/scadenza/index.vue")
  },
  {
    name: "admin-contenuti-scadenzario-elettorale-id",
    path: "/admin/contenuti/scadenzario-elettorale/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/scadenzario-elettorale/[id].vue")
  },
  {
    name: "admin-contenuti-scadenzario-elettorale",
    path: "/admin/contenuti/scadenzario-elettorale",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/contenuti/scadenzario-elettorale/index.vue")
  },
  {
    name: "admin-drive-file-id",
    path: "/admin/drive/file/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/drive/file/[id].vue")
  },
  {
    name: "admin-drive-folder-id",
    path: "/admin/drive/folder/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/drive/folder/[id].vue")
  },
  {
    name: "admin-drive",
    path: "/admin/drive",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/drive/index.vue")
  },
  {
    name: "admin-hub-id",
    path: "/admin/hub/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/hub/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/index.vue")
  },
  {
    name: "admin-memoweb-id",
    path: "/admin/memoweb/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/memoweb/[id].vue")
  },
  {
    name: "admin-memoweb",
    path: "/admin/memoweb",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/memoweb/index.vue")
  },
  {
    name: "admin-menu-id",
    path: "/admin/menu/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/menu/[id].vue")
  },
  {
    name: "admin-menu",
    path: "/admin/menu",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/menu/index.vue")
  },
  {
    name: "admin-newsletter-id",
    path: "/admin/newsletter/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/newsletter/[id].vue")
  },
  {
    name: "admin-newsletter",
    path: "/admin/newsletter",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/newsletter/index.vue")
  },
  {
    name: "admin-prodotti-id",
    path: "/admin/prodotti/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/prodotti/[id].vue")
  },
  {
    name: "admin-prodotti",
    path: "/admin/prodotti",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/prodotti/index.vue")
  },
  {
    name: "admin-quesiti-inviati-id",
    path: "/admin/quesiti-inviati/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/quesiti-inviati/[id].vue")
  },
  {
    name: "admin-quesiti-inviati",
    path: "/admin/quesiti-inviati",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/quesiti-inviati/index.vue")
  },
  {
    name: "admin-richieste-demo-id",
    path: "/admin/richieste-demo/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/richieste-demo/[id].vue")
  },
  {
    name: "admin-richieste-demo",
    path: "/admin/richieste-demo",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/richieste-demo/index.vue")
  },
  {
    name: "admin-utenti-id",
    path: "/admin/utenti/:id()",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/utenti/[id].vue")
  },
  {
    name: "admin-utenti",
    path: "/admin/utenti",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/utenti/index.vue")
  },
  {
    name: "admin-utilita-cache",
    path: "/admin/utilita/cache",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/utilita/cache/index.vue")
  },
  {
    name: "admin-utilita-contenuti",
    path: "/admin/utilita/contenuti",
    meta: {"layout":"admin"},
    component: () => import("/app/pages/admin/utilita/contenuti/index.vue")
  },
  {
    name: "amministrazione-id",
    path: "/amministrazione/:id()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/amministrazione/[id].vue")
  },
  {
    name: "area-area_slug-id-slug",
    path: "/area/:area_slug()/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[area_slug]/[id]-[slug].vue")
  },
  {
    name: "area-area_slug-hub-hub_id-hub_slug-id-slug",
    path: "/area/:area_slug()/hub/:hub_id()-:hub_slug()/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[area_slug]/hub/[hub_id]-[hub_slug]/[id]-[slug].vue")
  },
  {
    name: "area-area_slug-hub-id-slug",
    path: "/area/:area_slug()/hub/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[area_slug]/hub/[id]-[slug].vue")
  },
  {
    name: "area-area_slug-notizie",
    path: "/area/:area_slug()/notizie",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[area_slug]/notizie.vue")
  },
  {
    name: "area-area_slug-scadenzario",
    path: "/area/:area_slug()/scadenzario",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[area_slug]/scadenzario.vue")
  },
  {
    name: "area-slug",
    path: "/area/:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/area/[slug].vue")
  },
  {
    name: "cerca",
    path: "/cerca",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/cerca/index.vue")
  },
  {
    name: "clienti",
    path: "/clienti",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/clienti/index.vue")
  },
  {
    name: "cookie",
    path: "/cookie",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/cookie/index.vue")
  },
  {
    name: "dettaglio-id-slug",
    path: "/dettaglio/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/dettaglio/[id]-[slug].vue")
  },
  {
    name: "dichiarazione-accessibilita",
    path: "/dichiarazione-accessibilita",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/dichiarazione-accessibilita/index.vue")
  },
  {
    name: "file-id",
    path: "/file/:id()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/file/[id].vue")
  },
  {
    name: "gazzetta-e-portali-pubblici",
    path: "/gazzetta-e-portali-pubblici",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/gazzetta-e-portali-pubblici/index.vue")
  },
  {
    name: "hub-hub_id-hub_slug-id-slug",
    path: "/hub/:hub_id()-:hub_slug()/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/hub/[hub_id]-[hub_slug]/[id]-[slug].vue")
  },
  {
    name: "hub-id-slug",
    path: "/hub/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/hub/[id]-[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "informazioni-per-il-cittadino",
    path: "/informazioni-per-il-cittadino",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/informazioni-per-il-cittadino/index.vue")
  },
  {
    name: "memoweb-id-slug",
    path: "/memoweb/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/memoweb/[id]-[slug].vue")
  },
  {
    name: "memoweb-memoweb_id-memoweb_slug-id-slug",
    path: "/memoweb/:memoweb_id()-:memoweb_slug()/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/memoweb/[memoweb_id]-[memoweb_slug]/[id]-[slug].vue")
  },
  {
    name: "memoweb",
    path: "/memoweb",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/memoweb/index.vue")
  },
  {
    name: "myomnia",
    path: "/myomnia",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/myomnia/index.vue")
  },
  {
    name: "note-legali",
    path: "/note-legali",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/note-legali/index.vue")
  },
  {
    name: "notizie",
    path: "/notizie",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/notizie/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/privacy/index.vue")
  },
  {
    name: "quesiti-id",
    path: "/quesiti/:id()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/quesiti/[id].vue")
  },
  {
    name: "quesiti-assegnati",
    path: "/quesiti/assegnati",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/quesiti/assegnati/index.vue")
  },
  {
    name: "quesiti",
    path: "/quesiti",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/quesiti/index.vue")
  },
  {
    name: "riviste-id-slug",
    path: "/riviste/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/riviste/[id]-[slug].vue")
  },
  {
    name: "riviste-rivista_id-rivista_slug-id-slug",
    path: "/riviste/:rivista_id()-:rivista_slug()/:id()-:slug()",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/riviste/[rivista_id]-[rivista_slug]/[id]-[slug].vue")
  },
  {
    name: "riviste",
    path: "/riviste",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/riviste/index.vue")
  },
  {
    name: "scadenzario",
    path: "/scadenzario",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/scadenzario/index.vue")
  },
  {
    name: "servizi",
    path: "/servizi",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/servizi/index.vue")
  },
  {
    name: "tutorial",
    path: "/tutorial",
    meta: {"colorMode":"light"},
    component: () => import("/app/pages/tutorial/index.vue")
  }
]