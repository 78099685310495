import { CONTENT_TYPE_ENUM, LINK_TYPE_ENUM, NEWS_TYPE_ENUM, type BaseContent, type FrontContent, type Scadenza, type ScadenzaElettorale, type User } from "@gasparigit/omnia-sdk";
import { DateFormatter } from "@internationalized/date";
import type { CalendarEvent } from "calendar-link";
import type { ContentOverrides, CreateLinkConfig } from "~/utils/types";

export function isNotAllowed(data: any): data is { allowed: boolean } {
  return data && "allowed" in data && !data.allowed;
}

export function overrideContent<T extends BaseContent | FrontContent>(content: T): T & ContentOverrides {
  return { ...content, abstract: content.abstract || "", body: content.body || "", tags: content.tags || [] };
}

export function flatTree<T extends object>(tree: T[], parentKey: keyof T) {
  const finalTree: T[][] = [];

  tree?.forEach((node) => {
    const nodeParents = parentKey in node && Array.isArray(node[parentKey]) ? (node[parentKey] as T[]) : null;

    if (!nodeParents?.length) {
      finalTree.push([node]);

      return;
    }

    const treeParents = flatTree(nodeParents, parentKey);

    treeParents.forEach((parent) => {
      finalTree.push([...parent, node]);
    });
  });

  return finalTree;
}

export function getContentType(content: BaseContent | FrontContent) {
  if (content.contentable_type === "notizia" && "news_type" in content) return NEWS_TYPE_ENUM[content.news_type];
  if (content.contentable_type === "link" && "link_type" in content) return LINK_TYPE_ENUM[content.link_type];

  return CONTENT_TYPE_ENUM[content.contentable_type];
}

export function userHasRole(user: User | null, role: string | string[]) {
  if (!user || !user.roles?.length) return;

  return user.roles?.filter((r) => (Array.isArray(role) ? role.includes(r.name) : r.name === role)).length > 0;
}

export function createEvent(content: ScadenzaElettorale | Scadenza) {
  return {
    title: content.title,
    description: `<p><a href="${link({ content }, false, true)}">Apri in Progetto Omnia</a></p>` + content.body,
    start: content.date,
    allDay: true,
    url: link({ content }, false, true),
  } as CalendarEvent;
}

export function link(config: CreateLinkConfig, forAdmin: boolean = false, absolute: boolean = false): string | undefined {
  if (!absolute) return relativeLink(config, forAdmin);

  return useRequestURL().origin + relativeLink(config, forAdmin);
}

function relativeLink(config: CreateLinkConfig, forAdmin: boolean = false): string | undefined {
  if (forAdmin) return "/admin/" + linkForAdmin(config);

  const df = new DateFormatter("sv-SE", { day: "2-digit", month: "2-digit", year: "numeric" });

  const { area, hub, content, frontType, rivista, numeroRivista, userQuestion, memoweb, chain, file } = config;
  let queryParams = {} as any;

  if (userQuestion) return `/quesiti/${userQuestion.id}`;

  if (rivista && numeroRivista) return `/riviste/${rivista.id}-${rivista.slug}/${numeroRivista.id}-${numeroRivista.slug}`;
  if (rivista) return `/riviste/${rivista.id}-${rivista.slug}`;

  if (content?.contentable_type === "scadenza" && content.date) {
    queryParams["date"] = df.format(content.date);
  }

  if (content && "front_url" in content && content.front_url && !usePreviewMode().value) {
    return content.front_url + "?ref=" + useRequestURL().origin;
  }

  if (chain?.length) {
    queryParams["ref"] = chain.map((m) => (typeof m === "number" ? m : m.id)).join(".");
  }

  if (frontType) {
    queryParams["section"] = frontType;
  }

  const queryParamsStr = Object.keys(queryParams).length ? "?" + new URLSearchParams(queryParams).toString() : "";

  if (memoweb && content) return `/memoweb/${memoweb.id}-${memoweb.slug}/${content.id}-${content.slug}` + queryParamsStr;
  if (area && hub && content) return `/area/${area.slug}/hub/${hub.id}-${hub.slug}/${content.id}-${content.slug}` + queryParamsStr;
  if (hub && content) return `/hub/${hub.id}-${hub.slug}/${content.id}-${content.slug}` + queryParamsStr;
  if (area && content) return `/area/${area.slug}/${content.id}-${content.slug}` + queryParamsStr;

  if (area && hub) return `/area/${area.slug}/hub/${hub.id}-${hub.slug}` + queryParamsStr;
  if (area) return `/area/${area.slug}` + queryParamsStr;
  if (hub) return `/hub/${hub.id}-${hub.slug}` + queryParamsStr;

  if (content) return `/dettaglio/${content.id}-${content.slug}` + queryParamsStr;

  if (memoweb) return `/memoweb/${memoweb.id}-${memoweb.slug}`;

  if (file) return `/file/${file.id}`;
}

function linkForAdmin(config: CreateLinkConfig): string | undefined {
  const { area, hub, content, frontType, rivista, numeroRivista, userQuestion, memoweb, file, menu } = config;

  if (content) return `contenuti/${content.contentable_type}/${content.id}`;
  if (hub) return `hub/${hub.id}`;
  if (menu) return `menu/${menu.id}`;
  if (area) return `aree/${area.id}`;

  if (memoweb) return `memoweb/${memoweb.id}`;

  if (numeroRivista) return `contenuti/rivista/${numeroRivista.rivista_id}/numero/${numeroRivista.id}`;
  if (rivista) return `contenuti/rivista/${rivista.id}`;

  if (userQuestion) return `quesiti-inviati/${userQuestion.id}`;

  if (file) return `drive/file/${file.id}`;
}
