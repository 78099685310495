import { GenericCache, IndexedDBCachingStrategy, OmniaApi, PersonalAccessToken, type AccessToken, type CacheStore, type CachingStrategy } from "@gasparigit/omnia-sdk";

class CookieCachingStrategy extends GenericCache {
  constructor() {
    super(new CookieCacheStore());
  }
}

class CookieCacheStore implements CacheStore {
  protected getCookieDomain() {
    const config = useRuntimeConfig();
    return import.meta.server ? config.cookiesDomain : config.public.cookiesDomain;
  }

  public get(key: string): string | null {
    const cookieValue = useCookie(key, { domain: this.getCookieDomain() }).value;
    if (!cookieValue) return null;

    let returnCookieValue: AccessToken;
    if (typeof cookieValue === "string") returnCookieValue = { accessToken: cookieValue };
    else returnCookieValue = cookieValue;

    return JSON.stringify(returnCookieValue);
  }

  public set(key: string, value: string): void {
    const accessToken = JSON.parse(value);

    useCookie(key, { domain: this.getCookieDomain(), maxAge: 60 * 60 * 24 * 365 * 3 }).value = accessToken["accessToken"];
  }

  public remove(key: string): void {
    useCookie(key, { domain: this.getCookieDomain() }).value = null;
  }
}

class CookiePersonalAccessToken extends PersonalAccessToken {
  protected override get cache(): CachingStrategy {
    return new CookieCachingStrategy();
  }
}

export const useOmniaSdk = (serverApi?: boolean) => {
  const config = useRuntimeConfig();

  let useCache = config.public.useCache.toString() === "true";

  if (useCache) {
    useCache = import.meta.server ? false : !useRoute().path.startsWith("/admin"); // Always disable cache for Admin and for SSR
  }

  let previewMode = serverApi ? false : usePreviewMode();

  const omniaSdk = new OmniaApi({
    rootUrl: import.meta.server ? config.omniaApiBase : config.public.omniaApiBase,
    authenticationStrategy: serverApi ? undefined : new CookiePersonalAccessToken(), // Store login access token in cookies (necessary for SSR)
    cachingStrategy: serverApi ? undefined : new IndexedDBCachingStrategy(), // Cache data in IndexedDB
    cachingTTL: 1000 * 60 * 30, // Cache for 30 minutes
    cachingEnabled: useCache,
    previewMode: typeof previewMode === "boolean" ? previewMode : previewMode.value,
  });

  if (typeof previewMode !== "boolean") {
    watch(previewMode, (val) => {
      omniaSdk.sdkConfig.previewMode = val;
    });
  }

  return omniaSdk;
};
