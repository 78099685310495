import type { User } from "@gasparigit/omnia-sdk";
import { processError } from "~/lib/utils/errors";

export const useLoggedUser = (errorHandler?: null | ((error: any) => void), reload = true, onLogin?: null | ((user: User) => void), name: string = "logged-user") => {
  const omniaSdk = useOmniaSdk();
  const userState = useState<User | null>(name, () => null);

  return useAsyncData(
    name,
    async () => {
      try {
        if (!reload && userState.value !== undefined) {
          return userState.value;
        }

        const hasToken = !!useCookie("omnia-sdk:access:token").value;

        if (!hasToken) {
          userState.value = null;
          return null;
        }

        const user = await omniaSdk.auth.user.me();
        userState.value = user;

        if (user && onLogin) {
          onLogin(user);
        }

        return user;
      } catch (error) {
        userState.value = null;

        if (errorHandler) {
          errorHandler(error);
        } else if (errorHandler !== null) {
          processError(error);
        }

        return null;
      }
    },
    {
      transform: (user) => {
        if (user) {
          userState.value = user;
        }
        return user;
      },
    }
  );
};
