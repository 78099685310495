<script setup lang="ts">
import type { NuxtError } from "#app";
import { userHasRole } from "~/lib/utils/contents";

const { data: loggedUser } = useLoggedUser(null, true);
const preview = usePreviewMode();

defineProps({
  error: Object as () => NuxtError,
});

const handleError = () => clearError({ redirect: "/" });
</script>

<template>
  <div class="bg-primary-950 text-white h-screen w-screen flex flex-col gap-3 px-3 text-center items-center justify-center">
    <Icon class="w-36 h-36" :name="error?.statusCode === 404 ? 'fluent-emoji:face-with-monocle' : 'fluent-emoji:exploding-head'" />
    <h1 class="text-9xl font-bold">{{ error?.statusCode }}</h1>
    <h2 class="text-3xl">{{ error?.message }}</h2>
    <p>È possibile contattare la redazione all’indirizzo <a href="mailto:info@progettoomnia.it">info@progettoomnia.it</a></p>
    <button variant="secondary" @click="handleError">Torna alla Home</button>
    <div class="flex items-center gap-2 mt-5" v-if="loggedUser && userHasRole(loggedUser, ['SuperAdmin', 'Amministratore', 'Editore'])">
      <Label for="preview_mode">Anteprima</Label>
      <Switch id="preview_mode" v-model:checked="preview" @update:checked="clearError() && refreshNuxtData()" />
    </div>
  </div>
</template>
