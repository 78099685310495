<script setup lang="ts">
import type { Cookie } from "@dargmuesli/nuxt-cookie-control/dist/runtime/types";
import { LucideChevronsUpDown, LucideCookie } from "lucide-vue-next";
import type { GenericObject } from "vee-validate";

const preferencesOpened = ref(false);

const { cookiesEnabled, cookiesEnabledIds, isConsentGiven, isModalActive, moduleOptions } = useCookieControl();
const { disableAnalytics, enableAnalytics, initialize } = useGtag();

const opened = ref<boolean>(isModalActive.value || !isConsentGiven.value);

watch(
  () => cookiesEnabledIds.value,
  (current, previous) => {
    if (!previous?.includes("google-analytics") && current?.includes("google-analytics")) {
      initialize();
      enableAnalytics();
    }

    if (previous?.includes("google-analytics") && !current?.includes("google-analytics")) {
      disableAnalytics();
    }
  },
  { deep: true }
);

const setCookies = (cookiesIds: string[]) => {
  isConsentGiven.value = cookiesIds.length > 0;

  cookiesEnabled.value = [...moduleOptions.cookies.necessary, ...moduleOptions.cookies.optional.filter((cookieOptional: Cookie) => cookiesIds.includes(cookieOptional.id))];
  cookiesEnabledIds.value = cookiesEnabled.value.map((cookie) => cookie.id);
};

function acceptAll() {
  setCookies(moduleOptions.cookies.optional.map((cookie) => cookie.id));
  opened.value = false;
  preferencesOpened.value = false;
}

function refuseAll() {
  setCookies([]);
  opened.value = false;
  preferencesOpened.value = false;
}

function save(values: GenericObject) {
  setCookies(Object.keys(values).filter((id) => values[id]));
  opened.value = false;
  preferencesOpened.value = false;
}
</script>

<template>
  <div class="hidden">
    <CookieControl />
  </div>

  <Dialog v-model:open="opened">
    <DialogTrigger as-child>
      <Button variant="outline" size="icon" class="fixed bottom-5 right-5 rounded-full shadow-lg z-[9999] print:hidden size-12">
        <LucideCookie class="text-muted-foreground size-7" />
        <span class="sr-only">Gestisci il consenso ai cookie</span>
      </Button>
    </DialogTrigger>
    <DialogScrollContent class="max-w-xl" @pointer-down-outside.prevent>
      <Form @submit="save">
        <DialogHeader>
          <DialogTitle>Gestisci il consenso ai cookie</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <p class="text-base text-left">
          Per fornire le migliori esperienze, utilizziamo tecnologie come i cookie per memorizzare e/o accedere alle informazioni del dispositivo. Il consenso a queste tecnologie ci permetterà di
          elaborare dati come il comportamento di navigazione o ID unici su questo sito. Non acconsentire o ritirare il consenso può influire negativamente su alcune caratteristiche e funzioni.
        </p>
        <Collapsible v-model:open="preferencesOpened">
          <div class="flex items-center justify-between space-x-4 py-2">
            <span class="text-sm font-semibold">Gestisci singolarmente</span>
            <CollapsibleTrigger as-child>
              <Button variant="ghost" size="sm" class="w-9 p-0">
                <LucideChevronsUpDown class="h-4 w-4" />
                <span class="sr-only">Apri/chiudi preferenze</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent>
            <div>
              <div class="space-y-2">
                <FormField
                  v-slot="{ componentField, value, handleChange }"
                  v-for="cookie in [...moduleOptions.cookies.necessary, ...moduleOptions.cookies.optional]"
                  :key="cookie.id"
                  :name="cookie.id"
                  :value="cookie.id === 'necessary' || cookiesEnabledIds?.includes(cookie.id)"
                >
                  <FormItem class="flex flex-row items-center justify-between rounded-lg border p-3 gap-2">
                    <div class="space-y-0.5">
                      <FormLabel class="text-sm">{{ cookie.id === "ncc_f" ? "Funzionali" : cookie.name }}</FormLabel>
                      <FormDescription class="text-xs">{{ cookie.description }}</FormDescription>
                    </div>
                    <FormControl>
                      <Switch v-bind="componentField" :checked="value" @update:checked="handleChange" :disabled="cookie.id === 'necessary'" />
                    </FormControl>
                  </FormItem>
                </FormField>
              </div>
            </div>
          </CollapsibleContent>
        </Collapsible>
        <p class="text-sm mt-5">L’informativa cookie completa è <NuxtLink class="link" href="/cookie">disponibile a questo indirizzo</NuxtLink></p>
        <DialogFooter class="mt-4 gap-1">
          <Button v-if="preferencesOpened" type="submit">Salva</Button>
          <Button v-if="!preferencesOpened" @click="refuseAll()">Rifiuta tutti</Button>
          <Button v-if="!preferencesOpened" @click="acceptAll()">Accetta tutti</Button>
        </DialogFooter>
      </Form>
    </DialogScrollContent>
  </Dialog>
</template>
